import classNames from 'classnames';
import { format } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { mixed, object, string } from 'yup';

import { externalRoutes } from '../../../../api';

import { FORM_PARTS_TYPES, FORM_TYPES } from './data';

import EndDateSelect from './end-date-select';
import PassengersSelect from './passengers-select';
import PlaceOfDepartureSelect from './place-of-departure-select';
import PlaceOfDestinationSelect from './place-of-destination-select';
import StartDateSelect from './start-date-select';
import NightsRangeSelect from './nights-range-select';

export const FORM_KEYS = {
  adultPassengersCount: 'adultPassengersCount',
  childPassengers: 'childPassengers',
  endDate: 'endDate',
  minNights: 'minNights',
  maxNights: 'maxNights',
  placeOfDeparture: 'placeOfDeparture',
  placeOfDestination: 'placeOfDestination',
  startDate: 'startDate',
  startDateFinal: 'startDateFinal',
};

const useStyles = createUseStyles<any>((theme: any) => ({
  travelFormContainer: {
    width: '100%',
    boxShadow: '0px 4px 12px rgb(0 0 0 / 15%)',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,

    '@media(min-width: 1024px)': {
      display: 'flex',
    },
  },
  promoCt: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 9px 14px 12px',
    height: 66,
    backgroundColor: theme.white,
    cursor: 'not-allowed',
  },
  label: {
    marginBottom: 6,
    color: theme.emperor,
    fontSize: '0.8125rem',
    fontWeight: '500',
    lineHeight: '0.9375rem',
  },
  value: {
    color: theme.textBlack,
    lineHeight: '1.125rem',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
    height: 66,
    backgroundColor: theme.primaryColor,
    color: theme.white,
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.125rem',
    border: 'none',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,

    '&:hover': {
      backgroundColor: theme.primaryFocusColor,
    },

    '@media(min-width: 1024px)': {
      width: 'unset',
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 4,
    },
  },
  disabledButton: {
    backgroundColor: theme.darkGray,
    cursor: 'not-allowed',

    '&:hover': {
      backgroundColor: theme.darkGray,
    },
  },
}));

const startDate = new Date();
startDate.setUTCDate(startDate.getUTCDate() + 5);
startDate.setUTCHours(0, 0, 0, 0);

const endDate = new Date(startDate);
endDate.setUTCDate(endDate.getUTCDate() + 10);

const initialValues = {
  adultPassengersCount: 1,
  childPassengers: [],
  endDate: endDate,
  minNights: 7,
  maxNights: 10,
  startDate: startDate,
  startDateFinal: startDate,
  placeOfDeparture: {
    value: 61,
    label: 'Минск',
  },
  placeOfDestination: null,
  promocode: 'DELIVIO',
};

const TravelForm: React.FC<Props> = ({ currentFormTypeId }) => {
  const classes = useStyles();

  const formikRef = useRef<FormikProps<TravelFormValues>>(null);

  const validationSchema = object({
    placeOfDestination: object().shape({
      label: string(),
    }),
    placeOfDeparture: mixed().test('isNotEmptyField', 'поле не может быть пустым', (value) => {
      if (
        currentFormTypeId === FORM_TYPES.tours.id
        // || currentFormTypeId === FORM_TYPES.transfers.id
      ) {
        return value;
      }

      return true;
    }),
  });

  useEffect(() => {
    formikRef.current?.validateForm();
  }, [currentFormTypeId]);

  const onSubmit = async (values: TravelFormValues) => {
    const params = createURLAndParamsObject(values, currentFormTypeId);

    const formElement = document.createElement('form');
    formElement.method = 'POST';
    formElement.action = params.url;
    formElement.target = '_blank';

    for (const key in params.params) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', key);
      // @ts-ignore
      input.setAttribute('value', params.params[key]);
      formElement.appendChild(input);
    }

    // @ts-ignore
    window.dataLayer.push({ event: 'traveldelivioFindTour' });

    document.body.appendChild(formElement);
    formElement.submit();
  };

  const renderFormParts = () => {
    const formParts = [] as React.ReactNode[];

    FORM_TYPES[currentFormTypeId].parts.forEach((item: string) => {
      if (item === FORM_PARTS_TYPES.placeOfDeparture) {
        formParts.push(
          <PlaceOfDepartureSelect
            key={item}
            defaultValue={initialValues.placeOfDeparture}
            formTypeId={currentFormTypeId}
          />,
        );
      }

      if (item === FORM_PARTS_TYPES.placeOfDestination) {
        formParts.push(
          <PlaceOfDestinationSelect
            key={item}
            defaultValue={initialValues.placeOfDestination}
            formTypeId={currentFormTypeId}
          />,
        );
      }

      if (item === FORM_PARTS_TYPES.startDate) {
        formParts.push(<StartDateSelect key={item} formTypeId={currentFormTypeId} />);
      }

      if (item === FORM_PARTS_TYPES.endDate) {
        formParts.push(<EndDateSelect key={item} formTypeId={currentFormTypeId} />);
      }

      if (item === FORM_PARTS_TYPES.nightsRange) {
        formParts.push(<NightsRangeSelect key={item} formTypeId={currentFormTypeId} />);
      }

      if (item === FORM_PARTS_TYPES.passengers) {
        formParts.push(<PassengersSelect key={item} />);
      }
    });

    formParts.push(
      <div className={classes.promoCt} key="promocode">
        <span className={classes.label}>Промокод:</span>
        <span className={classes.value}>DELIVIO</span>
      </div>,
    );

    return formParts;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formikRef}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ isValid }) => (
        <Form className={classes.travelFormContainer}>
          {renderFormParts()}

          <button
            disabled={!isValid}
            type="submit"
            className={classNames(classes.button, { [classes.disabledButton]: !isValid })}
          >
            Найти
          </button>
        </Form>
      )}
    </Formik>
  );
};

const createURLAndParamsObject = (values: TravelFormValues, currentFormTypeId: string) => {
  const childPassengersAges = values.childPassengers.reduce((newString, item, index) => {
    if (index !== 0) {
      newString += ',';
    }
    return newString + item;
  }, '');

  let url;
  let params: any;

  if (currentFormTypeId === FORM_TYPES.tours.id) {
    url = externalRoutes.post.searchTours;

    params = {
      namespace: 'tours',
      cityId: values.placeOfDeparture.value,
      countryId: values.placeOfDestination?.countryId,
      after: format(values.startDate, 'dd.MM.yyyy'),
      before: format(values.startDateFinal, 'dd.MM.yyyy'),
      minNights: values.minNights,
      maxNights: values.maxNights,
    };

    if (params.after !== params.before) {
      params.dtype = 'period';
    }

    if (values.placeOfDestination?.regionId) {
      params.regions = values.placeOfDestination.regionId;
    }

    if (values.placeOfDestination?.hotelId) {
      params.hotels = values.placeOfDestination.hotelId;
    }
  } else if (currentFormTypeId === FORM_TYPES.hotels.id) {
    url = externalRoutes.post.searchHotels;

    params = {
      namespace: 'main',
      countryId: values.placeOfDestination?.countryId,
      regions: values.placeOfDestination?.regionId,
      from_date: format(values.startDate, 'dd.MM.yyyy'),
      to_date: format(values.endDate, 'dd.MM.yyyy'),
      external: 1,
      ecat: 1,
    };
    // } else if (currentFormTypeId === FORM_TYPES.flights.id) {
    //   url = externalRoutes.post.searchFlights;

    //   params = {
    //     namespace: 'flights',
    //     // iataFrom: ,
    //     // iataTo: ,
    //     from_date: format(values.startDate, 'dd.MM.yyyy'),
    //     // to_date: RT ? format(values.endDate, 'dd.MM.yyyy') : null, дата возврата (если это RT маршрут)
    //     // route_type: // (OW для маршрута в одну сторону, или же RT)
    //   };
  } else {
    url = externalRoutes.post.searchTransfers;

    params = {
      namespace: 'etransfers',
      startpoint: values.placeOfDeparture?.startpoint,
      endpoint: values.placeOfDestination?.endpoint,
      from_date: format(values.startDate, 'dd.MM.yyyy'),
      // to_date: RT ? format(values.endDate, 'dd.MM.yyyy') : null, дата возврата (если это RT маршрут)
      // route_type=(one_way_hotel для маршрута в одну сторону, или же round_trip)
    };
  }

  if (childPassengersAges.length) {
    params.ages = childPassengersAges;
  }

  params.adults = values.adultPassengersCount;
  params.promocode = values.promocode;

  return {
    url,
    params,
  };
};

export type TravelFormValues = {
  adultPassengersCount: number;
  childPassengers: any[];
  endDate: Date;
  minNights: number;
  maxNights: number;
  startDate: Date;
  startDateFinal: Date;
  placeOfDeparture: any;
  placeOfDestination: any;
  promocode: string;
};

type Props = { currentFormTypeId: string };

export default TravelForm;
