export const getLabelWithPluralForm = (value: number, titles: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  const text = titles[value % 100 > 4 && value % 100 < 20 ? 2 : cases[value % 10 < 5 ? value % 10 : 5]];

  return `${value} ${text}`;
};

export const getOptionTypeLabel = (type: string) => {
  switch (type) {
    case 'airport':
      return 'Аэропорты';

    case 'region':
      return 'Регионы';

    case 'city':
      return 'Города';

    case 'hotel':
      return 'Отели';
  }
};
