import classNames from 'classnames';
import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';

import Icon from '../../../components/icon';
import ServiceTypeSwitcher from '../../../components/service-type-switcher';

import TravelForm from './components/travel-form';
import {
  ADVANTAGES_LIST,
  COUNTRIES,
  ENTERTAINMENTS_LIST,
  FORM_TYPES,
  INCLUDED_SERVICES,
  TAGS,
} from './components/data';
import travelPageStyles from './components/travel-page-styles';

export const STEPS_TO_GET_DISCOUNT = [
  {
    desc: 'Подобрать подходящий тур или услугу с помощью  формы подбора',
    iconUrl: '/svg/travel/search-step.svg',
  },
  {
    desc: (
      <span>
        При оформлении бронирования ввести промокод <b>DELIVIO</b> в соответствующее поле “Промокод”
      </span>
    ),
    iconUrl: '/svg/travel/discount-step.svg',
  },
  {
    desc: 'Оплатить тур банковской картой',
    iconUrl: '/svg/travel/card-step.svg',
  },
  {
    desc: 'Распечатать документы на вылет из личного кабинета',
    iconUrl: '/svg/travel/tickets-step.svg',
  },
];

const Travel: React.FC = () => {
  const classes = travelPageStyles();

  const [currentFormTypeId, setCurrentFormTypeId] = useState(FORM_TYPES.tours.id);

  const handleFormSwitcherClick = (type: string) => {
    setCurrentFormTypeId(type);
  };

  const renderHeader = () => {
    return (
      <div className={classes.header}>
        <div className={classNames(classes.headerTitle, classes.grid)}>
          <div className={classes.headerLogo}>
            <Icon
              containerProps={{ className: classes.logoIconCt }}
              svgData={{
                src: `/svg/logo.svg`,
              }}
            />
            <div className={classes.logoTitleCt}>
              <span className={classes.logoTitle}>travel</span>
              <Icon
                containerProps={{ className: classes.travelIconCt }}
                classList={classes.travelIcon}
                svgData={{
                  src: `/svg/travel.svg`,
                }}
              ></Icon>
            </div>
          </div>

          {/* eslint-disable-next-line no-irregular-whitespace */}
          <span className={classes.companyDescription}>ООО "Деливери софтвеа". УНП 193175076</span>
        </div>

        <div className={classNames(classes.headerBg, classes.grid)}>
          <ServiceTypeSwitcher className={classes.serviceTypeSwitcher} />
        </div>
      </div>
    );
  };

  const renderFormSwitcher = () => {
    return (
      <div className={classes.formSwitcher}>
        {Object.values(FORM_TYPES).map(({ id, name }: any) => (
          <div
            onClick={() => handleFormSwitcherClick(id)}
            className={classNames(classes.formSwitcherButton, {
              [classes.formSwitcherActiveButton]: id === currentFormTypeId,
            })}
            key={id}
          >
            {name}
          </div>
        ))}
      </div>
    );
  };

  const renderEntertainments = () => {
    return (
      <div className={classNames(classes.entertainmentsList, classes.grid)}>
        {ENTERTAINMENTS_LIST.map((item) => {
          return (
            <div className={classes.entertainmentItem} key={item.title}>
              <img className={classes.entertainmentImg} src={item.iconUrl} alt={item.title} />
              <div className={classes.entertainmentTitle}>{item.title}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAdvantages = () => {
    return (
      <div className={classNames(classes.advantagesCt, classes.grid)}>
        <h3 className={classes.advantagesListTitle}>Почему Delivio Travel?</h3>

        <div className={classes.advantagesList}>
          <div className={classNames(classes.advantageCard, classes.advantageCardWithLogo)}>
            <Icon
              containerProps={{ className: classes.advantageLogoCt }}
              classList={classes.advantageLogo}
              svgData={{ src: '/svg/travel/cutted-logo.svg' }}
            />

            <div className={classes.discountText}>
              Скидка <span className={classes.discountNumber}>10%</span> по промокоду
            </div>
            <div className={classes.discountCoupon}>“DELIVIO”</div>
          </div>

          {ADVANTAGES_LIST.map((item) => {
            return (
              <div className={classes.advantageCard} key={item.title}>
                <Icon
                  containerProps={{ className: classes.advantageIconCt }}
                  svgData={{ src: item.iconUrl, height: 24, width: 24 }}
                />
                <div className={classes.advantageTitle}>{item.title}</div>
                <div className={classes.advantageDesc}>{item.desc}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderTezTour = () => {
    return (
      <div className={classNames(classes.tezTourCt, classes.grid)}>
        <img src="/images/travel/tez-tour-logo.png" alt="" />
        <div className={classes.tezTourTitle}>Туры формирует ведущий туроператор Беларуси TEZ TOUR</div>
        <div className={classes.tezTourDesc}>ООО "КОМПАНИЯ ТЕЗ ТУР", УНП 190685430</div>
      </div>
    );
  };

  const renderStepsToGetDiscount = () => {
    return (
      <div className={classNames(classes.discountCt, classes.grid)}>
        <h2 className={classes.discountTitle}>Как получить скидку</h2>
        <div className={classes.discountSubtitle}>Четыре шага к отличному отдыху</div>

        <div className={classes.discountListCt}>
          <div>
            {STEPS_TO_GET_DISCOUNT.map((item, index) => (
              <div className={classes.discountItem} key={item.iconUrl}>
                <div className={classes.discountItemImg}>
                  <span className={classes.discountItemNumber}>{index + 1}</span>
                  <Icon
                    svgData={{
                      src: item.iconUrl,
                      width: 64,
                      height: 64,
                    }}
                  />
                </div>

                <div className={classes.discountItemDesc}>
                  {item.desc}

                  {index === 0 ? (
                    <ScrollLink
                      to="travelFormContainer"
                      smooth={true}
                      duration={1000}
                      delay={100}
                      className={classes.discountItemLink}
                    >
                      Перейти к форме подбора
                      <Icon
                        containerProps={{ className: classes.discountItemLinkIcon }}
                        svgData={{
                          src: '/svg/travel/green-arrow-up.svg',
                          width: 8,
                          height: 12,
                        }}
                      />
                    </ScrollLink>
                  ) : null}
                </div>
              </div>
            ))}
          </div>

          <picture className={classes.discountImg}>
            <source srcSet="/images/travel/road-2.png" type="image/png" media="(min-width: 1024px)" />
            <img src="/images/travel/road-1.png" alt="" />
          </picture>
        </div>
      </div>
    );
  };

  const renderIncludedServices = () => {
    return (
      <div className={classNames(classes.includedServicesCt, classes.grid)}>
        <h2 className={classes.includedServicesTitle}>В стоимость наших туров входит</h2>

        <div className={classes.includedServicesList}>
          {INCLUDED_SERVICES.map((item) => (
            <div className={classes.includedService} key={item.title}>
              <Icon
                svgData={{
                  src: item.iconUrl,
                  width: 56,
                  height: 56,
                }}
              />
              <span>{item.title}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderServices = () => {
    return (
      <div className={classes.servicesCt}>
        <div className={classes.servicesTitleCt}>
          <h2 className={classes.servicesTitle}>Туристические услуги</h2>
          <div className={classes.servicesDesc}>
            <p>
              К бронированию доступны не только полнопакетные туры по массовым направлениям, но и отдельные
              виды туристических услуг с возможностью забронировать услугу в любую точку мира.
            </p>
            <p>
              Используйте промокод <span className={classes.advantageTitle}>“DELIVIO”</span>, чтобы получить
              10% скидку!
            </p>
          </div>
        </div>

        <div className={classNames(classes.tagsCt, classes.grid)}>
          {TAGS.map((item: string) => (
            <div key={item} className={classes.tag}>
              {item}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderCountries = () => {
    return (
      <div className={classNames(classes.countriesCt, classes.grid)}>
        <div className={classes.countriesWrapper}>
          <h2 className={classes.countriesTitle}>Направления путешествий</h2>

          <div className={classes.countriesList}>
            {COUNTRIES.map((item) => {
              return (
                <div key={item.title} className={classes.countryRow}>
                  <Icon
                    svgData={{
                      src: item.iconUrl,
                    }}
                  />
                  <span className={classes.countryTitle}>{item.title}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className={classNames(classes.footerCt, classes.grid)}>
        <div className={classes.infoCt}>
          <div className={classes.logoWrapper}>
            <Icon
              containerProps={{ className: classes.logoIconCt }}
              svgData={{
                src: `/svg/logo.svg`,
                width: 110,
                height: 28,
              }}
            />
            {/* eslint-disable-next-line no-irregular-whitespace */}
            <div className={classes.companyDescription}>ООО "Деливери софтвеа". УНП 193175076</div>
          </div>

          <div className={classes.info}>
            <span>
              ООО "Деливио Технолоджис"
              <br />
              Адрес: Республика Беларусь, 220012, г. Минск, ул. Толбухина, д. 2, 3 этаж, офис 310В
            </span>
            <span>
              Свидетельство о государственной регистрации №193480123 выдано 20.10.2020 Минским горисполкомом
              <br />
              Время работы: 8:00-23:00
            </span>
          </div>

          <div className={classes.terms}>
            ООО «Деливио Технолоджис» не уполномочено на принятие и удовлетворение требований потребителей в
            отношении услуг сторонних сервисов, рекламные материалы которых размещены на настоящем сайте. С
            соответствующими требованиями потребителям необходимо обращаться непосредственно к исполнителю
            услуг.
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderHeader()}

      <div className={classNames(classes.formCt, classes.grid)} id="travelFormContainer">
        <h1 className={classes.formTitle}>Платформа по поиску выгодных туров</h1>

        <div className={classes.formWrapper}>
          <h3 className={classes.formSubtitle}>Бронируйте туры онлайн со скидкой 10%</h3>

          {renderFormSwitcher()}
          <TravelForm currentFormTypeId={currentFormTypeId} />
        </div>
      </div>

      {renderEntertainments()}

      {renderAdvantages()}

      {/* {renderTezTour()} */}

      {renderStepsToGetDiscount()}

      {renderIncludedServices()}

      {renderServices()}

      {renderCountries()}

      {renderFooter()}
    </div>
  );
};

export default Travel;
