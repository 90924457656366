type FormType = {
  id: string;
  name: string;
  parts: string[];
};

export const FORM_TYPES: { [key: string]: FormType } = {
  tours: {
    id: 'tours',
    name: 'Туры',
    parts: ['placeOfDeparture', 'placeOfDestination', 'startDate', 'nightsRange', 'passengers'],
  },
  hotels: {
    id: 'hotels',
    name: 'Отели',
    parts: ['placeOfDestination', 'startDate', 'endDate', 'passengers'],
  },
  // flights: {
  //   id: 'flights',
  //   name: 'Авиабилеты',
  //   parts: ['placeOfDeparture', 'placeOfDestination', 'startDate', 'endDate', 'passengers'],
  // },
  // transfers: {
  //   id: 'transfers',
  //   name: 'Трансферы',
  //   parts: ['placeOfDeparture', 'placeOfDestination', 'startDate', 'endDate', 'passengers'],
  // },
};

export const FORM_PARTS_TYPES = {
  endDate: 'endDate',
  nightsRange: 'nightsRange',
  passengers: 'passengers',
  placeOfDeparture: 'placeOfDeparture',
  placeOfDestination: 'placeOfDestination',
  startDate: 'startDate',
};

export const ENTERTAINMENTS_LIST = [
  {
    title: 'Vip-отдых и услуги',
    iconUrl: '/svg/travel/vip.svg',
  },
  {
    title: 'Пляжный отдых',
    iconUrl: '/svg/travel/beach.svg',
  },
  {
    title: 'Экскурсионные туры и круизы',
    iconUrl: '/svg/travel/tours.svg',
  },
  {
    title: 'Отдых с детьми',
    iconUrl: '/svg/travel/with-children.svg',
  },
  {
    title: 'Горнолыжные курорты',
    iconUrl: '/svg/travel/mountains.svg',
  },
  {
    title: 'Туры на острова',
    iconUrl: '/svg/travel/islands.svg',
  },
  {
    title: 'Свадебные туры',
    iconUrl: '/svg/travel/wedding-tours.svg',
  },
  {
    title: 'Отдых без детей',
    iconUrl: '/svg/travel/without-children.svg',
  },
  {
    title: 'SPA и термы',
    iconUrl: '/svg/travel/spa.svg',
  },
];

export const ADVANTAGES_LIST = [
  {
    title: 'Оплата туров в рассрочку',
    desc: 'Внесите предоплату 25% от стоимости – и тур ваш! Остальную часть оплаты необходимо внести до установленных сроков, но не позднее 14 дней до вылета',
    iconUrl: '/svg/travel/card.svg',
  },
  {
    title: 'Покупка тура онлайн',
    desc: 'Удобный сервис по поиску и бронированию туров, отелей, трансферов, экскурсий и аренде авто на одной платформе онлайн, не выходя из дома',
    iconUrl: '/svg/travel/desktop.svg',
  },
  {
    title: 'Помощь в подборе тура',
    desc: 'Доступна дистанционная консультация по подбору тура от экспертов в туризме',
    iconUrl: '/svg/travel/faq.svg',
  },
  {
    title: 'Поддержка 24/7',
    desc: 'Наша служба поддержки поможет забронировать тур онлайн. Если у вас возник вопрос в поездке, ваш персональный менеджер будет круглосуточно на связи и найдёт решение в кратчайший срок.',
    iconUrl: '/svg/travel/time.svg',
  },
];

export const COUNTRIES = [
  {
    title: 'Австрия',
    iconUrl: '/svg/travel/countries/austria.svg',
  },
  {
    title: 'Андорра',
    iconUrl: '/svg/travel/countries/andorra.svg',
  },
  {
    title: 'Беларусь',
    iconUrl: '/svg/travel/countries/belarus.svg',
  },
  {
    title: 'Болгария',
    iconUrl: '/svg/travel/countries/bulgaria.svg',
  },
  {
    title: 'Венгрия',
    iconUrl: '/svg/travel/countries/hungary.svg',
  },
  {
    title: 'Греция',
    iconUrl: '/svg/travel/countries/greece.svg',
  },
  {
    title: 'Доминикана',
    iconUrl: '/svg/travel/countries/dominican.svg',
  },
  {
    title: 'Египет',
    iconUrl: '/svg/travel/countries/egypt.svg',
  },
  {
    title: 'Индонезия',
    iconUrl: '/svg/travel/countries/indonesia.svg',
  },
  {
    title: 'Испания',
    iconUrl: '/svg/travel/countries/spain.svg',
  },
  {
    title: 'Италия',
    iconUrl: '/svg/travel/countries/italy.svg',
  },
  {
    title: 'Кипр',
    iconUrl: '/svg/travel/countries/cyprus.svg',
  },
  {
    title: 'Китай',
    iconUrl: '/svg/travel/countries/china.svg',
  },
  {
    title: 'Куба',
    iconUrl: '/svg/travel/countries/cuba.svg',
  },
  {
    title: 'Латвия',
    iconUrl: '/svg/travel/countries/latvia.svg',
  },
  {
    title: 'Маврикий',
    iconUrl: '/svg/travel/countries/mauritius.svg',
  },
  {
    title: 'Мальдивы',
    iconUrl: '/svg/travel/countries/maldives.svg',
  },
  {
    title: 'Мексика',
    iconUrl: '/svg/travel/countries/mexico.svg',
  },
  {
    title: 'ОАЭ',
    iconUrl: '/svg/travel/countries/UAE.svg',
  },
  {
    title: 'Таиланд',
    iconUrl: '/svg/travel/countries/thailand.svg',
  },
  {
    title: 'Турция',
    iconUrl: '/svg/travel/countries/turkey.svg',
  },
  {
    title: 'Франция',
    iconUrl: '/svg/travel/countries/france.svg',
  },
  {
    title: 'Чехия',
    iconUrl: '/svg/travel/countries/czech.svg',
  },
  {
    title: 'Шри-Ланка',
    iconUrl: '/svg/travel/countries/sri-lanka.svg',
  },
];

export const TAGS = [
  'туры от туроператора',
  'отели',
  'отель+авиабилет',
  'трансферы',
  'экскурсии',
  'аренда машин',
  'виллы и апартаменты ',
];

export const INCLUDED_SERVICES = [
  {
    title: 'Перелет туда и обратно',
    iconUrl: '/svg/travel/flight.svg',
  },
  {
    title: 'Трансфер в отель и обратно',
    iconUrl: '/svg/travel/transfer.svg',
  },
  {
    title: 'Проживание в отеле',
    iconUrl: '/svg/travel/hotel.svg',
  },
  {
    title: 'Питание',
    iconUrl: '/svg/travel/food.svg',
  },
  {
    title: 'Медстраховка',
    iconUrl: '/svg/travel/medical-insurance.svg',
  },
];

export const CHILD_AGES = [
  {
    value: 0,
    label: 'до 1 года',
  },
  {
    value: 1,
    label: '1 год',
  },
  {
    value: 2,
    label: '2 года',
  },
  {
    value: 3,
    label: '3 года',
  },
  {
    value: 4,
    label: '4 года',
  },
  {
    value: 5,
    label: '5 лет',
  },
  {
    value: 6,
    label: '6 лет',
  },
  {
    value: 7,
    label: '7 лет',
  },
  {
    value: 8,
    label: '8 лет',
  },
  {
    value: 9,
    label: '9 лет',
  },
  {
    value: 10,
    label: '10 лет',
  },
  {
    value: 11,
    label: '11 лет',
  },
  {
    value: 12,
    label: '12 лет',
  },
  {
    value: 13,
    label: '13 лет',
  },
  {
    value: 14,
    label: '14 лет',
  },
  {
    value: 15,
    label: '15 лет',
  },
  {
    value: 16,
    label: '16 лет',
  },
  {
    value: 17,
    label: '17 лет',
  },
];
