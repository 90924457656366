import React, { SyntheticEvent } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Icon from './icon';

const useStyles = createUseStyles<any>((theme: any) => ({
  stepperContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonCt: {
    padding: 0,
    borderRadius: 10,
    border: 'none',

    '&:hover': {
      backgroundColor: theme.silver,
    },
  },
  button: {
    display: 'flex',
  },
  value: {
    marginLeft: 8,
    marginRight: 8,
  },
}));

const Stepper: React.FC<IStepperProps> = (props) => {
  const {
    onAddHandler,
    onSubtractHandler,
    value,
    weight,
    inline = false,
    min = 0,
    max = Infinity,
    valueClassName,
    className,
  } = props;
  const classes = useStyles();
  const handleAddClick = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.blur();
    if (onAddHandler !== undefined) {
      onAddHandler(e);
    }
  };
  const handleSubtractClick = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.blur();
    if (onSubtractHandler !== undefined) {
      onSubtractHandler(e);
    }
  };

  return (
    <div className={classNames(classes.stepperContainer, className)}>
      <button
        disabled={min === value}
        onClick={handleSubtractClick}
        type="button"
        className={classes.buttonCt}
      >
        <Icon
          containerProps={{ className: classes.button }}
          svgData={{
            src: '/svg/subtruct-circle.svg',
            width: 20,
            height: 20,
          }}
        />
      </button>

      {value !== undefined && !weight && (
        <div className={classNames(classes.value, valueClassName)}>{value}</div>
      )}
      {weight && <div className={classes.value}>{weight}</div>}

      <button disabled={max === value} onClick={handleAddClick} type="button" className={classes.buttonCt}>
        <Icon
          containerProps={{ className: classes.button }}
          svgData={{
            src: '/svg/add-circle.svg',
            width: 20,
            height: 20,
          }}
        />
      </button>
    </div>
  );
};

interface IStepperProps {
  onAddHandler(e: SyntheticEvent): void;
  onSubtractHandler(e: SyntheticEvent): void;
  value?: number;
  weight?: number | string;
  inline?: boolean;
  min?: number;
  max?: number;
  className?: string;
  valueClassName?: any;
}

export default Stepper;
