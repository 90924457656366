import { isBefore, isSameDay } from 'date-fns';

type RangeParams = {
  from: Date;
  to: Date;
  isFirstStep: boolean;
};

// steps:
// 1) choose new first date (could be single)
// 2) chose second date

export const addDayToRange = (day: Date, { from, to, isFirstStep = true }: RangeParams): RangeParams => {
  if (isFirstStep) {
    from = day;
    to = day;
    isFirstStep = false;
  } else if (isBefore(day, from)) {
    from = day;
    isFirstStep = true;
  } else if (isSameDay(day, to)) {
    from = day;
    to = day;
  } else {
    to = day;
    isFirstStep = true;

    if (isBefore(to, from)) {
      to = from;
      from = day;
    }
  }

  return { from, to, isFirstStep };
};
