import { AxiosResponse } from 'axios';
import { upperFirst } from 'lodash';
import React from 'react';
import { createUseStyles } from 'react-jss';

import API, { route } from '../../../../api';
import { getOptionTypeLabel } from '../../../../helpers/labels';

import { FORM_TYPES } from './data';
import FormikSelect from './select-wrapper';

const useStyles = createUseStyles<any>((theme: any) => ({
  placeOfDestinationContainer: {
    width: '100%',

    '& .select__control': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,

      '@media(min-width: 1024px)': {
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 4,
      },
    },
  },
  optionLabel: {
    padding: 8,
    backgroundColor: theme.wildSand,
    color: theme.emperor,
    fontWeight: '500',
    fontSize: '0.8125rem',
    lineHeight: '1rem',
    textTransform: 'initial',
  },
}));

const PlaceOfDestinationSelect: React.FC<Props> = ({ defaultValue, formTypeId }) => {
  const classes = useStyles();

  const loadOptions = (inputValue: string) => {
    if (!inputValue) {
      return;
    }

    switch (formTypeId) {
      case FORM_TYPES.tours.id:
        return loadDeparturesOptions(inputValue);

      // case FORM_TYPES.transfers.id:
      //   return loadTransfersOptions(inputValue);
    }
  };

  const formatGroupLabel = (data: any) => <div className={classes.optionLabel}>{data.label}</div>;

  return (
    <div className={classes.placeOfDestinationContainer}>
      <FormikSelect
        defaultValue={defaultValue}
        labelText={getLabel(formTypeId)}
        name="placeOfDeparture"
        placeholder={getPlaceholder(formTypeId)}
        isClearable
        loadOptions={loadOptions}
        formatGroupLabel={formatGroupLabel}
        isAsync
      />
    </div>
  );
};

const loadDeparturesOptions = async (inputValue: string) => {
  if (!inputValue) {
    return;
  }

  const url = route.travel.get.citiesOfDeparture(upperFirst(inputValue));
  const response: AxiosResponse<any> = await API.get(url);

  const data = response.data.reduce((newObj: any, item: any) => {
    const optionData = { ...item, value: item.id, label: item.name };
    const countryName = item.country.name;

    if (!newObj[countryName]) {
      newObj[countryName] = {
        label: countryName,
        options: [],
      };
    }

    newObj[countryName].options.push(optionData);

    return newObj;
  }, {});

  return Object.values(data);
};

const loadTransfersOptions = async (inputValue: string) => {
  const url = route.travel.get.transfers(upperFirst(inputValue));
  const response: AxiosResponse<any> = await API.get(url);

  const data = response.data.reduce((newObj: any, item: any) => {
    const optionData = {
      ...item,
      startpoint: item.id,
      label: item.name,
    };

    if (!newObj[item.type]) {
      newObj[item.type] = {
        label: getOptionTypeLabel(item.type),
        options: [],
      };
    }

    newObj[item.type].options.push(optionData);

    return newObj;
  }, {});

  return Object.values(data);
};

const getLabel = (formTypeId: string) => {
  switch (formTypeId) {
    // case FORM_TYPES.transfers.id:
    //   return 'Трансфер из:';

    default:
      return 'Город вылета:';
  }
};

const getPlaceholder = (formTypeId: string) => {
  switch (formTypeId) {
    // case FORM_TYPES.transfers.id:
    //   return 'Аэропорт, город, курорт';

    default:
      return 'Город';
  }
};

type Props = {
  defaultValue: { label: string; value: number } | null;
  formTypeId: string;
};

export default PlaceOfDestinationSelect;
