import classNames from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

import { RESTAURANTS, SHOPS } from '../constants/routes';
import config from '../config';

import Icon from './icon';

const useStyles = createUseStyles<any>((theme: any) => ({
  serviceTypeSwitcherContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 56,
    marginBottom: 68,

    '@media(min-width: 48em)': {
      marginRight: '0.9375rem',
      marginLeft: '0.9375rem',
    },
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
    height: 64,
    maxWidth: 590,
    padding: 8,
    backgroundColor: theme.white,
    boxShadow: `inset 0 0 1px 1px ${theme.mediumGray}`,
    borderRadius: 12,
    cursor: 'pointer',

    '&:nth-child(n+2)': {
      marginLeft: 8,

      '@media(min-width: 768px)': {
        marginLeft: 18,
      },
      '@media(min-width: 1024px)': {
        marginLeft: 24,
      },
      '@media(min-width: 1440px)': {
        marginLeft: 30,
      },
    },

    '@media(min-width: 640px)': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: 16,
      paddingRight: 0,
      height: 56,
    },
    '@media(min-width: 768px)': {
      paddingLeft: 20,
    },
    '@media(min-width: 1024px)': {
      padding: '20px 0 28px 22px',
      height: 100,
    },
    '@media(min-width: 1440px)': {
      padding: '20px 0 25px 30px',
    },
  },
  activeButton: {
    boxShadow: `0px 4px 28px rgba(56, 65, 69, 0.1), inset 0px -3px 0px ${theme.primaryColor}`,
  },
  travelButton: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 2,
    marginBottom: 2,

    '& $description': {
      marginTop: 9,
    },

    '@media(min-width: 1024px)': {
      paddingRight: 24,
    },
    '@media(min-width: 1440px)': {
      paddingRight: 30,
    },
  },
  restaurantAndShopIcon: {
    display: 'flex',
    alignItems: 'center',
    width: 30,
    height: 24,

    '@media(min-width: 640px)': {
      marginRight: 10,
    },
    '@media(min-width: 768px)': {
      width: 38,
      height: 30,
      marginRight: 15,
    },
    '@media(min-width: 1024px)': {
      width: 45,
      height: 36,
    },
    '@media(min-width: 1440px)': {
      marginRight: 25,
    },
  },
  travelCt: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexGrow: 1,

    '@media(min-width: 640px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
  },
  logoIconCt: {
    display: 'flex',
    alignItems: 'center',

    '@media(min-width: 640px)': {
      marginRight: 7,
    },
  },
  logoIcon: {
    width: 70,
    height: 18,

    '@media(min-width: 640px)': {
      width: 77,
      height: 20,
    },
    '@media(min-width: 768px)': {
      width: 84,
      height: 22,
    },
    '@media(min-width: 1024px)': {
      width: 97,
      height: 25,
    },
  },
  titleCt: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: theme.textGray,
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: '1.1875rem',

    '@media(min-width: 640px)': {
      fontSize: '1.25rem',
      lineHeight: '1.4375rem',
    },
    '@media(min-width: 768px)': {
      fontSize: '1.625rem',
      lineHeight: '1.875rem',
    },
    '@media(min-width: 1024px)': {
      fontSize: '1.875rem',
      lineHeight: '2.1875rem',
    },
  },
  activeTitle: {
    color: theme.textBlack,
  },
  travelTitleCt: {
    position: 'relative',
    width: 'max-content',
    lineHeight: '1rem',
  },
  travelTitle: {
    color: theme.fiord2,
    fontSize: '0.875rem',
    fontWeight: '500',

    '@media(min-width: 640px)': {
      fontSize: '0.9375rem',
      lineHeight: '1.125rem',
    },
    '@media(min-width: 768px)': {
      fontSize: '1rem',
      lineHeight: '1.1875rem',
    },
    '@media(min-width: 1024px)': {
      fontSize: '1.1875rem',
      lineHeight: '1.375rem',
    },
  },
  travelIconCt: {
    position: 'absolute',
    right: -15,
    top: -10,
  },
  travelIcon: {
    '@media(min-width: 1024px)': {
      width: 18,
    },
  },
  description: {
    display: 'none',

    '@media(min-width: 1024px)': {
      display: 'block',
      marginTop: 2,
      fontSize: '0.8125rem',
      lineHeight: '0.9375rem',
      color: theme.darkerGray,
    },
    '@media(min-width: 1440px)': {
      fontSize: '1rem',
      lineHeight: '1.1875rem',
    },
  },
  centeredDescription: {
    textAlign: 'center',
  },
}));

const BUTTONS = {
  RESTAURANTS: 'restaurants',
  SHOPS: 'shops',
  TRAVEL: 'travel',
};

const ServiceTypeSwitcher: React.FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.serviceTypeSwitcherContainer, className)}>
      <Link
        to={{ pathname: `${config.apiURL}/${RESTAURANTS}` }}
        id={BUTTONS.RESTAURANTS}
        className={classes.button}
        target="_blank"
      >
        <Icon
          containerProps={{ className: classes.restaurantAndShopIcon }}
          svgData={{
            src: '/svg/restaurant-logo.svg',
          }}
        />
        <div className={classes.titleCt}>
          <span className={classes.title}>Рестораны</span>
          <span className={classes.description}>Доставка блюд из ресторанов</span>
        </div>
      </Link>

      <Link
        to={{ pathname: `${config.apiURL}/${SHOPS}` }}
        id={BUTTONS.SHOPS}
        className={classes.button}
        target="_blank"
      >
        <Icon
          containerProps={{ className: classes.restaurantAndShopIcon }}
          svgData={{
            src: '/svg/shop-logo.svg',
          }}
        />
        <div className={classes.titleCt}>
          <span className={classes.title}>Магазины</span>
          <span className={classes.description}>Доставка продуктов из магазинов</span>
        </div>
      </Link>

      <div id={BUTTONS.TRAVEL} className={classNames(classes.button, classes.activeButton)}>
        <div className={classes.travelButton}>
          <div className={classes.travelCt}>
            <Icon
              containerProps={{ className: classes.logoIconCt }}
              classList={classes.logoIcon}
              svgData={{
                src: `/svg/logo.svg`,
              }}
            />
            <div className={classes.travelTitleCt}>
              <span className={classes.travelTitle}>travel</span>
              <Icon
                containerProps={{ className: classes.travelIconCt }}
                classList={classes.travelIcon}
                svgData={{
                  src: `/svg/travel.svg`,
                }}
              ></Icon>
            </div>
          </div>
          <span className={classNames(classes.description, classes.centeredDescription)}>
            Поиск туров со скидкой 10%
          </span>
        </div>
      </div>
    </div>
  );
};

type Props = {
  className?: any;
};

export default ServiceTypeSwitcher;
