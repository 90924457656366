import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles<any>((theme: any) => ({
  grid: {
    paddingLeft: 16,
    paddingRight: 16,

    '@media(min-width: 768px)': {
      paddingLeft: 24,
      paddingRight: 24,
    },
    '@media(min-width: 1440px)': {
      paddingLeft: 112,
      paddingRight: 112,
    },
  },
  headerBg: {
    paddingTop: 24,
    paddingBottom: 24,
    backgroundImage: 'url(/images/travel/header-bg-768px.png)',
    backgroundSize: 'cover',

    '@media(min-width: 768px)': {
      paddingTop: 32,
      paddingBottom: 32,
      backgroundImage: 'url(/images/travel/header-bg-1440px.png)',
    },
    '@media(min-width: 1024px)': {
      paddingTop: 20,
      paddingBottom: 32,
    },
    '@media(min-width: 1440px)': {
      paddingTop: 24,
    },
  },
  headerBgImg: {
    width: '100%',
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 16,
    paddingBottom: 3,
    height: 68,
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.mediumGray}`,
  },
  headerLogo: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  logoIconCt: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
  logoTitleCt: {
    position: 'relative',
    width: 'max-content',
  },
  logoTitle: {
    color: theme.fiord2,
    fontSize: '1.3125rem',
    fontWeight: '500',
    lineHeight: '1.5625rem',
  },
  travelIconCt: {
    position: 'absolute',
    right: -19,
    top: -14,
  },
  travelIcon: {
    width: 20,
    height: 11,
  },
  companyDescription: {
    marginTop: 5,
    color: theme.silver,
    fontSize: '0.625rem',
    lineHeight: '1.125rem',
  },
  serviceTypeSwitcher: {
    marginTop: 0,
    marginBottom: 0,

    '@media(min-width: 48em)': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  formCt: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 24,
    paddingBottom: 24,
    backgroundImage: 'url(/images/travel/form-bg-768px.png)',
    backgroundPositionX: 'right',
    backgroundSize: 'cover',

    '@media(min-width: 640px)': {
      paddingTop: 32,
      paddingBottom: 40,
    },

    '@media(min-width: 768px)': {
      paddingBottom: 48,
    },
    '@media(min-width: 1024px)': {
      paddingTop: 56,
      paddingBottom: 134,
      backgroundImage: 'url(/images/travel/form-bg-1440px.png)',
    },
  },
  formTitle: {
    margin: 0,
    color: theme.white,
    fontSize: '1.875rem',
    fontWeight: '700',
    lineHeight: '2.5rem',
    textAlign: 'center',

    '@media(min-width: 640px)': {
      fontSize: '3.5rem',
      lineHeight: '3.75rem',
    },
  },
  formWrapper: {
    '@media(min-width: 640px)': {
      width: 470,
    },
    '@media(min-width: 1024px)': {
      width: '100%',
    },
  },
  formSubtitle: {
    marginTop: 16,
    marginBottom: 32,
    color: theme.white,
    fontSize: '1.25rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
    textAlign: 'center',

    '@media(min-width: 640px)': {
      marginTop: 24,
      marginBottom: 64,
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
    },
    '@media(min-width: 1024px)': {
      marginTop: 16,
      marginBottom: 86,
    },
  },
  formSwitcher: {
    display: 'flex',
    marginBottom: 30,
  },
  formSwitcherButton: {
    color: theme.white,
    cursor: 'pointer',
    fontSize: '1rem',
    lineHeight: '1.1875rem',

    '&:nth-child(n+2)': {
      marginLeft: 20,

      '@media(min-width: 1024px)': {
        marginLeft: 24,
      },
    },
  },
  formSwitcherActiveButton: {
    fontWeight: '700',
    position: 'relative',

    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      marginTop: 5,
      width: '100%',
      height: 4,
      borderRadius: 4,
      backgroundColor: theme.primaryColor,
    },
  },
  entertainmentsList: {
    display: 'flex',
    justifyContent: 'center',
    height: 150,
    paddingTop: 32,
    paddingBottom: 20,
    overflowX: 'auto',
    backgroundColor: theme.white,
  },
  entertainmentItem: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 110,

    '&:nth-child(n+2)': {
      marginLeft: 16,

      '@media(min-width: 1440px)': {
        marginLeft: 24,
      },
    },
  },
  entertainmentImg: {
    height: 40,
    marginBottom: 10,
  },
  entertainmentTitle: {
    color: theme.black,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    textAlign: 'center',
  },
  advantagesCt: {
    paddingTop: 40,
    paddingBottom: 40,
    backgroundColor: theme.alabaster,

    '@media(min-width: 640px)': {
      paddingBottom: 48,
    },
    '@media(min-width: 768px)': {
      paddingBottom: 56,
    },
    '@media(min-width: 1024px)': {
      paddingTop: 56,
      paddingBottom: 80,
    },
  },
  advantagesListTitle: {
    marginTop: 0,
    marginBottom: 32,
    color: theme.textBlack,
    fontSize: '1.875rem',
    fontWeight: '700',
    lineHeight: '2.5rem',
    textAlign: 'center',

    '@media(min-width: 640px)': {
      marginBottom: 40,
      fontSize: '2.375rem',
      lineHeight: '2.75rem',
    },
    '@media(min-width: 1024px)': {
      marginBottom: 56,
    },
  },
  advantagesList: {
    display: 'grid',
    gridGap: 16,

    '@media(min-width: 640px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media(min-width: 768px)': {
      gridGap: 24,
    },
    '@media(min-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media(min-width: 1440px)': {
      gridGap: 32,
    },
  },
  advantageCard: {
    position: 'relative',
    padding: 24,
    backgroundColor: theme.white,
    borderRadius: 6,
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
  },
  advantageCardWithLogo: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 80,
    backgroundColor: theme.secondaryColor,

    '@media(max-width: 639px)': {
      height: 160,
    },
  },
  advantageLogoCt: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  advantageLogo: {
    height: 130,

    '@media(min-width: 640px)': {
      height: 140,
    },
  },
  discountText: {
    marginBottom: 5,
    color: theme.white,
    fontWeight: '500',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    textAlign: 'center',

    '@media(min-width: 640px)': {
      marginBottom: 16,
    },
    '@media(min-width: 1440px)': {
      marginBottom: 5,
    },
  },
  discountTextSmall: {
    fontSize: '0.875rem',
  },
  discountNumber: {
    color: theme.primaryColor,
  },
  discountCoupon: {
    color: theme.white,
    fontWeight: '500',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
  },
  advantageIconCt: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  advantageTitle: {
    marginBottom: 16,
    fontWeight: '500',
    lineHeight: '1.5rem',
  },
  advantageDesc: {
    color: theme.emperor,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
  },
  tezTourCt: {
    paddingTop: 40,
    paddingBottom: 16,
    backgroundPosition: 'right top',
    backgroundSize: 'cover',
    backgroundImage: 'url(/images/travel/tez-tour-768px.png)',

    '@media(min-width: 768px)': {
      backgroundImage: 'url(/images/travel/tez-tour-1440px.png)',
    },
  },
  tezTourTitle: {
    width: 410,
    color: theme.white,
    fontSize: '1.25rem',
    fontWeight: '500',
    lineHeight: '2rem',
  },
  tezTourDesc: {
    color: theme.white,
    fontSize: '0.625rem',
    lineHeight: '1.75rem',
  },
  discountCt: {
    paddingTop: 70,
    paddingBottom: 56,
    backgroundColor: theme.white,

    '@media(min-width: 640px)': {
      paddingTop: 72,
      paddingBottom: 72,
    },
    '@media(min-width: 768px)': {
      paddingTop: 80,
      paddingBottom: 80,
    },
    '@media(min-width: 1024px)': {
      paddingTop: 88,
      paddingBottom: 88,
    },
  },
  discountTitle: {
    marginTop: 0,
    marginBottom: 12,
    color: theme.textBlack,
    fontSize: '1.875rem',
    fontWeight: '700',
    lineHeight: '2.5rem',
    textAlign: 'center',

    '@media(min-width: 640px)': {
      marginBottom: 8,
      fontSize: '2.375rem',
      lineHeight: '2.75rem',
    },
  },
  discountSubtitle: {
    marginBottom: 55,
    color: theme.emperor,
    lineHeight: '1.5rem',
    textAlign: 'center',

    '@media(min-width: 640px)': {
      marginBottom: 60,
    },
    '@media(min-width: 768px)': {
      marginBottom: 72,
    },
  },
  discountListCt: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  discountItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 160,
    borderBottom: `1px solid ${theme.backgroundButtonFocus}`,
  },
  discountItemNumber: {
    position: 'absolute',
    top: -13,
    left: -13,
    color: theme.silver,
    fontWeight: '700',
    lineHeight: '1.125rem',
  },
  discountItemImg: {
    position: 'relative',
    minWidth: 64,
    marginLeft: 14,
    backgroundColor: theme.alabaster,
    borderRadius: 6,

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: -48,
      display: 'block',
      width: 64,
      height: 2,
      borderRadius: 1,
      backgroundColor: theme.darkGray,
    },
  },
  discountItemDesc: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 24,
    lineHeight: '1.5rem',

    '@media(min-width: 640px)': {
      marginLeft: 60,
    },
    '@media(min-width: 768px)': {
      marginLeft: 40,
    },
    '@media(min-width: 1024px)': {
      marginLeft: 60,
    },
  },
  discountItemLink: {
    display: 'flex',
    marginTop: 5,
    color: theme.brandTextGreen,
    cursor: 'pointer',
    fontWeight: '500',
    lineHeight: '1.5rem',
  },
  discountItemLinkIcon: {
    marginLeft: 10,
  },
  discountImg: {
    display: 'none',

    '@media(min-width: 768px)': {
      display: 'flex',
      flex: 1,
      minWidth: 286,
      height: 604,
      marginLeft: 24,
      borderRadius: 6,
    },
    '@media(min-width: 1024px)': {
      marginLeft: 43,
      minWidth: 384,
      height: 604,
    },
    '@media(min-width: 1440px)': {
      marginLeft: 84,
    },
  },
  includedServicesCt: {
    paddingTop: 64,
    paddingBottom: 64,
    backgroundColor: theme.alabaster,

    '@media(min-width: 1024px)': {
      paddingTop: 80,
      paddingBottom: 104,
    },
  },
  includedServicesTitle: {
    marginTop: 0,
    marginBottom: 32,
    color: theme.textBlack,
    fontSize: '1.875rem',
    fontWeight: '700',
    lineHeight: '2.5rem',
    textAlign: 'center',

    '@media(min-width: 640px)': {
      marginBottom: 48,
      fontSize: '2.375rem',
      lineHeight: '2.75rem',
    },
  },
  includedServicesList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  includedService: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: '500',

    '&:nth-child(n+2)': {
      marginLeft: 34,
    },
  },
  servicesCt: {
    paddingTop: 72,
    backgroundColor: theme.white,

    '@media(min-width: 768px)': {
      display: 'flex',
      paddingTop: 32,
      paddingLeft: 24,
      paddingRight: 24,
    },
    '@media(min-width: 1440px)': {
      paddingLeft: 112,
      paddingRight: 112,
    },
  },
  servicesTitleCt: {
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 32,

    '@media(min-width: 640px)': {
      marginBottom: 40,
    },
    '@media(min-width: 768px)': {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      flexDirection: 'column',
      marginRight: 24,
      marginBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
    '@media(min-width: 1440px)': {
      paddingLeft: 105,
      marginRight: 38,
    },
  },
  servicesTitle: {
    marginTop: 0,
    marginBottom: 28,
    color: theme.textBlack,
    fontSize: '1.875rem',
    fontWeight: '700',
    lineHeight: '2.5rem',

    '@media(min-width: 640px)': {
      fontSize: '2.375rem',
      lineHeight: '2.75rem',
    },
  },
  servicesDesc: {
    lineHeight: '1.5rem',
    fontWeight: '400',

    '& > p': {
      lineHeight: '1.5rem',
    },
  },
  tagsCt: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 32,
    backgroundImage: 'url(/images/travel/tags-bg-1.png)',
    backgroundPositionX: '64%',
    backgroundSize: 'cover',
    borderRadius: 6,

    '@media(min-width: 640px)': {
      paddingTop: 72,
      paddingBottom: 56,
    },
    '@media(min-width: 768px)': {
      flex: 1,
      paddingTop: 65,
      paddingBottom: 49,
      backgroundImage: 'url(/images/travel/tags-bg-2.png)',
    },
    '@media(min-width: 1024px)': {
      paddingTop: 120,
      paddingBottom: 104,
    },
  },
  tag: {
    marginRight: 16,
    marginBottom: 16,
    padding: '6px 16px 6px 16px',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    borderRadius: 18,
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontWeight: '500',

    '&:last-child': {
      marginRight: 0,
    },
  },
  countriesCt: {
    paddingTop: 70,
    paddingBottom: 64,
    backgroundColor: theme.white,

    '@media(min-width: 640px)': {
      paddingBottom: 78,
    },
    '@media(min-width: 768px)': {
      paddingTop: 130,
      paddingBottom: 100,
    },
  },
  countriesWrapper: {
    paddingLeft: 8,
    paddingRight: 8,

    '@media(width: 640px)': {
      gridColumn: '1/1',
      paddingLeft: 68,
      paddingRight: 68,
    },
    '@media(width: 1440px)': {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  countriesTitle: {
    marginTop: 0,
    marginBottom: 56,
    color: theme.textBlack,
    fontSize: '1.875rem',
    fontWeight: '700',
    lineHeight: '2.5rem',
    textAlign: 'center',

    '@media(min-width: 640px)': {
      fontSize: '2.375rem',
      lineHeight: '2.75rem',
    },
  },
  countriesList: {
    display: 'grid',
    gridRowGap: 24,
    gridTemplateColumns: '1fr 1fr',

    '@media(min-width: 768px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
  },
  countryRow: {
    display: 'flex',
    alignItems: 'center',
  },
  countryTitle: {
    marginLeft: 8,
    fontWeight: '500',
    lineHeight: '1.125rem',
  },
  footerCt: {
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: theme.wildSand,
  },
  logoWrapper: {
    gridColumn: '1/2',

    '@media(min-width: 640px)': {
      marginRight: 64,
    },
    '@media(min-width: 768px)': {
      marginRight: 54,
    },
    '@media(min-width: 1024px)': {
      marginRight: 44,
    },
    '@media(min-width: 1440px)': {
      marginRight: 150,
    },
  },
  infoCt: {
    '@media(min-width: 640px)': {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    marginBottom: 16,
    color: theme.boulder,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: -0.19,

    '& > span:first-child': {
      marginBottom: 16,

      '@media(min-width: 768px)': {
        marginRight: '10%',
        marginBottom: 0,
      },
    },

    '@media(min-width: 640px)': {
      gridColumn: '2/5',
      marginTop: 0,
    },
    '@media(min-width: 768px)': {
      flexDirection: 'row',
    },
  },
  terms: {
    color: theme.silver,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: -0.19,

    '@media(min-width: 640px)': {
      gridColumn: '1/5',
    },
    '@media(min-width: 768px)': {
      gridColumn: '2/5',
    },
  },
}));

export default useStyles;
