import axios from 'axios';

import config from '../config';

export const { apiURL } = config;
export const prefix = '/be';
export const API = axios.create({
  baseURL: apiURL,
});

const tezTour = 'https://delivio.teztour.by/search';

export const route = {
  travel: {
    get: {
      citiesOfDeparture: (city: string) =>
        `${prefix}/travel/v1/suggest?type=toursdepcity&lang=ru&query=${city}`,
      flights: (place: string) => `${prefix}/travel/v1/suggest?type=flights&lang=ru&query=${place}`,
      hotels: (place: string) => `${prefix}/travel/v1/suggest?type=hotels&lang=ru&query=${place}`,
      placesOfDestination: (place: string) => `${prefix}/travel/v1/suggest?type=tours&lang=ru&query=${place}`,
      transfers: (place: string) => `${prefix}/travel/v1/suggest?type=transfers&lang=ru&query=${place}`,
    },
  },
};

export const externalRoutes = {
  post: {
    searchFlights: `${tezTour}/flights`,
    searchHotels: `${tezTour}`,
    searchTours: `${tezTour}/tours`,
    searchTransfers: `${tezTour}/transfers`,
  },
};

export default API;
