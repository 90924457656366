import classNames from 'classnames';
import { add } from 'date-fns';
import { upperFirst } from 'lodash';
import { useField, useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

import DayPicker from '../../../../components/day-picker';
import { addDayToRange } from '../../../../components/day-picker/helpers';
import Icon from '../../../../components/icon';

import { FORM_TYPES } from './data';
import { FORM_KEYS, TravelFormValues } from './travel-form';

const todayDate = new Date();
const dateFormatOptions: any = { weekday: 'short', day: 'numeric', month: 'long' };
const dateFormatOptionsShort: any = { day: 'numeric', month: 'short' };

const useStyles = createUseStyles<any>((theme: any) => ({
  startDateFormContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px 14px 12px',
    minWidth: 188,
    height: 66,
    justifyContent: 'space-between',
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.catskillWhite}`,
    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.silver,
    },

    '@media(min-width: 1024px)': {
      padding: '12px 12px 14px 12px',
      borderBottom: 'none',
      borderRight: `1px solid ${theme.catskillWhite}`,
    },
  },
  inputCt: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 12,
  },
  label: {
    marginBottom: 6,
    color: theme.emperor,
    fontSize: '0.8125rem',
    fontWeight: '500',
    lineHeight: '0.9375rem',
    whiteSpace: 'nowrap',
  },
  value: {
    color: theme.textBlack,
    lineHeight: '1.125rem',
    whiteSpace: 'nowrap',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  popup: {
    display: 'none',
    color: theme.textBlack,
    position: 'absolute',
    overflow: 'hidden',
    top: 66,
    left: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: theme.white,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)',

    '&.active': {
      display: 'block',
      zIndex: 1,
    },
  },
}));

const StartDateSelect: React.FC<Props> = ({ formTypeId }) => {
  const classes = useStyles();

  const [startDateField] = useField(FORM_KEYS.startDate);
  const [startDateFinalField] = useField(FORM_KEYS.startDateFinal);
  const [endDateField] = useField(FORM_KEYS.endDate);
  const formContext = useFormikContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [chosenDates, setChosenDates] = useState<{
    from: Date;
    to: Date;
    isFirstStep: boolean;
  }>({
    from: startDateField.value,
    to: startDateFinalField.value,
    isFirstStep: true,
  });

  const hidePopup = () => {
    setIsPopupVisible(false);
    document.removeEventListener('click', hidePopupByOutsideClick);
  };

  const showPopup = () => {
    document.addEventListener('click', hidePopupByOutsideClick);
    setIsPopupVisible(true);
  };

  const hidePopupByOutsideClick = (e?: any) => {
    if (containerRef === null || (containerRef.current && !containerRef.current.contains(e.target))) {
      hidePopup();
    }
  };

  const handleDateChange = (date: Date) => {
    formContext.setFieldValue(startDateField.name, date);
    setChosenDates({ from: date, to: date, isFirstStep: true });
  };

  const handleDateRangeChange = (date: Date) => {
    const range = addDayToRange(date, chosenDates);

    setChosenDates(range);
    formContext.setValues({
      ...(formContext.values as TravelFormValues),
      [FORM_KEYS.startDate]: range.from,
      [FORM_KEYS.startDateFinal]: range.to,
    });
  };

  const handleChangeDateClick = (date: Date, modifiers: any) => {
    if (modifiers.disabled) {
      return;
    }

    switch (formTypeId) {
      case FORM_TYPES.tours.id: {
        handleDateRangeChange(date);
        break;
      }

      default: {
        handleDateChange(date);
      }
    }

    if (date.getTime() > endDateField.value.getTime()) {
      formContext.setFieldValue(endDateField.name, date);
    }
  };

  let fromDateLabel = '';
  let toDateLabel = '';
  let disabledDaysBefore = todayDate;
  let disabledDaysAfter;

  if (chosenDates.from.getTime() !== chosenDates.to.getTime()) {
    fromDateLabel = `${chosenDates.from.toLocaleDateString('ru', dateFormatOptionsShort)}`;
    toDateLabel = ` - ${chosenDates.to.toLocaleDateString('ru', dateFormatOptionsShort)}`;
  } else {
    fromDateLabel = `${chosenDates.from.toLocaleDateString('ru', dateFormatOptions)}`;

    const smallestFromDate = add(chosenDates.from, { days: -20 });
    disabledDaysBefore =
      !chosenDates.isFirstStep && smallestFromDate.getTime() >= todayDate.getTime()
        ? smallestFromDate
        : todayDate;
    disabledDaysAfter = !chosenDates.isFirstStep ? add(chosenDates.from, { days: 20 }) : undefined;
  }

  const dateLabel = `${upperFirst(fromDateLabel)}${upperFirst(toDateLabel)}` ?? '';

  return (
    <div ref={containerRef} className={classes.startDateFormContainer} onClick={showPopup}>
      <div className={classes.inputCt}>
        <span className={classes.label}>{getLabel(formTypeId)}</span>
        <span className={classes.value}>{dateLabel}</span>
      </div>

      <div className={classNames(classes.popup, { active: isPopupVisible })}>
        <DayPicker
          fromMonth={todayDate}
          hasRange={formTypeId === FORM_TYPES.tours.id}
          onDayClick={handleChangeDateClick}
          month={chosenDates.from as Date}
          selectedDays={[chosenDates.from, { from: chosenDates.from, to: chosenDates.to }]}
          modifiers={{ start: chosenDates.from, end: chosenDates.to }}
          disabledDays={[
            {
              before: disabledDaysBefore,
              after: disabledDaysAfter,
            },
          ]}
        />
      </div>

      <Icon
        containerProps={{ className: classes.icon }}
        svgData={{
          src: '/svg/travel/calendar.svg',
          width: 24,
          height: 24,
        }}
      />
    </div>
  );
};

const getLabel = (formTypeId: string) => {
  switch (formTypeId) {
    case FORM_TYPES.tours.id:
      return 'Дата начала тура:';

    case FORM_TYPES.hotels.id:
      return 'Дата заезда:';

    // case FORM_TYPES.flights.id:
    //   return 'Дата вылета:';

    // case FORM_TYPES.transfers.id:
    //   return 'Дата прилёта:';
  }
};

type Props = {
  formTypeId: string;
};

export default StartDateSelect;
