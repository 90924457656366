import React from 'react';

const Icon: React.FC<Props> = ({ containerProps, classList, svgData = {} }) => {
  return (
    <span {...containerProps}>
      <img className={classList} src={svgData.src} height={svgData.height} width={svgData.width} />
    </span>
  );
};

interface SvgData {
  height?: number;
  src: string;
  width?: number;
}

interface Props {
  containerProps?: any;
  classList?: any;
  svgData?: SvgData;
}

export default Icon;
