import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Range } from 'react-range';

import { getLabelWithPluralForm } from '../../../../helpers/labels';

import { FORM_KEYS, TravelFormValues } from './travel-form';

const STEP = 1;
const MIN = 1;
const MAX = 30;

const useStyles = createUseStyles<any>((theme: any) => ({
  startDateFormContainer: {
    position: 'relative',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 9px 14px 12px',
    height: 66,
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.catskillWhite}`,
    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.silver,
    },

    '@media(min-width: 1024px)': {
      borderBottom: 'none',
      borderRight: `1px solid ${theme.catskillWhite}`,
    },
  },
  label: {
    marginBottom: 6,
    color: theme.emperor,
    fontSize: '0.8125rem',
    fontWeight: '500',
    lineHeight: '0.9375rem',
    whiteSpace: 'nowrap',
  },
  value: {
    color: theme.textBlack,
    lineHeight: '1.125rem',
  },
  popup: {
    display: 'none',
    color: theme.textBlack,
    position: 'absolute',
    overflow: 'hidden',
    top: 66,
    left: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: theme.white,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)',

    '&.active': {
      display: 'block',
      zIndex: 1,
    },
  },
  sliderContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: 24,
    width: 300,
  },
  tip: {
    marginBottom: 8,
    color: theme.textGray,
    fontSize: '0.6875rem',
  },
  trackCt: {
    display: 'flex',
    height: 36,
    width: '100%',
  },
  track: {
    height: 5,
    width: '100%',
    borderRadius: 4,
    background: theme.primaryColor,
    alignSelf: 'center',
  },
  thumbCt: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    width: 24,
    borderRadius: 12,
    backgroundColor: theme.white,
    boxShadow: '0px 2px 6px #AAA',
  },
  thumb: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: '#CCC',
  },
  draggedThumb: {
    backgroundColor: theme.brandTextGreen,
  },
  output: {
    marginTop: 8,
  },
}));

const NightsCountSelect: React.FC<Props> = ({ formTypeId }) => {
  const classes = useStyles();

  const formContext = useFormikContext();
  const [minNightsField] = useField(FORM_KEYS.minNights);
  const [maxNightsField] = useField(FORM_KEYS.maxNights);

  const containerRef = useRef<HTMLDivElement>(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [values, setValues] = useState([minNightsField.value, maxNightsField.value]);

  const hidePopup = () => {
    setIsPopupVisible(false);
    document.removeEventListener('click', hidePopupByOutsideClick);
  };

  const showPopup = () => {
    document.addEventListener('click', hidePopupByOutsideClick);
    setIsPopupVisible(true);
  };

  const hidePopupByOutsideClick = (e?: any) => {
    if (containerRef === null || (containerRef.current && !containerRef.current.contains(e.target))) {
      hidePopup();
    }
  };

  const handleChange = (values: number[]) => {
    formContext.setValues({
      ...(formContext.values as TravelFormValues),
      [FORM_KEYS.minNights]: values[0],
      [FORM_KEYS.maxNights]: values[1],
    });
    setValues(values);
  };

  const label = getLabel(values);

  const renderSlider = () => {
    return (
      <div className={classes.sliderContainer}>
        <span className={classes.tip}>Перетащите ползунки, чтобы изменить количество ночей</span>
        <Range
          values={values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => handleChange(values)}
          renderTrack={({ props, children }) => (
            <div style={{ ...props.style }} className={classes.trackCt}>
              <div ref={props.ref} className={classes.track}>
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div {...props} style={{ ...props.style }} className={classes.thumbCt}>
              <div className={classNames(classes.thumb, { [classes.draggedThumb]: isDragged })} />
            </div>
          )}
        />
        <span className={classes.output}>{label}</span>
      </div>
    );
  };

  return (
    <div ref={containerRef} className={classes.startDateFormContainer} onClick={showPopup}>
      <span className={classes.label}>Количество ночей:</span>
      <span className={classes.value}>{label}</span>
      <div className={classNames(classes.popup, { active: isPopupVisible })}>{renderSlider()}</div>
    </div>
  );
};

const getLabel = (values: number[]) => {
  const titles = ['ночь', 'ночи', 'ночей'];
  const leftValue = values[0];
  const rightValue = values[1];
  const labelWithRightValue = getLabelWithPluralForm(rightValue, titles);

  if (leftValue === rightValue) {
    return labelWithRightValue;
  } else {
    return `${leftValue} - ${labelWithRightValue}`;
  }
};

type Props = {
  formTypeId: string;
};

export default NightsCountSelect;
