import classNames from 'classnames';
import { FieldArray, useField, useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

import FormikSelect from '../../../../components/formik/select';
import Icon from '../../../../components/icon';
import Stepper from '../../../../components/stepper';

import { CHILD_AGES } from './data';
import { FORM_KEYS } from './travel-form';

const useStyles = createUseStyles<any>((theme: any) => ({
  passengersFormContainer: {
    position: 'relative',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 9px 14px 12px',
    height: 66,
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.catskillWhite}`,
    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.silver,
    },

    '@media(min-width: 1024px)': {
      minWidth: 80,
      borderBottom: 'none',
      borderRight: `1px solid ${theme.catskillWhite}`,
    },
  },
  label: {
    marginBottom: 6,
    color: theme.emperor,
    fontSize: '0.8125rem',
    fontWeight: '500',
    lineHeight: '0.9375rem',
    whiteSpace: 'nowrap',
  },
  icon: {
    display: 'flex',
    marginRight: 4,
    marginBottom: 3,
  },
  passengersCountCt: {
    display: 'flex',
    alignItems: 'center',
  },
  passengersCount: {
    display: 'flex',
    alignItems: 'center',

    '&:nth-child(n+2)': {
      marginLeft: 12,
    },
  },
  popup: {
    display: 'none',
    padding: 24,
    position: 'absolute',
    top: 66,
    left: 0,
    width: 230,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: theme.white,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)',
    cursor: 'initial',

    '&.active': {
      display: 'block',
      zIndex: 1,
    },
  },
  passengersRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.625rem',

    '&:first-child': {
      marginBottom: 20,
    },

    '& > span': {
      fontSize: '0.875rem',
      lineHeight: '1rem',
    },
  },
  childPassengersList: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,

    '& > label:nth-child(n+2)': {
      marginTop: 10,
    },
  },
  childPassengersListDesc: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    color: theme.doveGray2,
  },
  stepperValue: {
    fontSize: '0.8125rem',
    fontWeight: '500',
    lineHeight: '0.9375rem',
  },
}));

const PassengersSelect: React.FC = () => {
  const classes = useStyles();

  const [adultsCountField] = useField(FORM_KEYS.adultPassengersCount);
  const [childsField] = useField(FORM_KEYS.childPassengers);
  const formContext = useFormikContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const hidePopup = () => {
    setIsMenuOpen(false);
    document.removeEventListener('click', hidePopupByOutsideClick);
  };

  const showPopup = () => {
    document.addEventListener('click', hidePopupByOutsideClick);
    setIsMenuOpen(true);
  };

  const hidePopupByOutsideClick = (e?: any) => {
    if (containerRef === null || (containerRef.current && !containerRef.current.contains(e.target))) {
      hidePopup();
    }
  };

  const handleAdultCountIncreased = () => {
    formContext.setFieldValue(adultsCountField.name, adultsCountField.value + 1);
  };

  const handleAdultCountDecreased = () => {
    formContext.setFieldValue(adultsCountField.name, adultsCountField.value - 1);
  };

  const handleChildsCountIncreased = () => {
    formContext.setFieldValue(childsField.name, [...childsField.value, CHILD_AGES[1].value]);
  };

  const handleChildsCountDecreased = () => {
    formContext.setFieldValue(childsField.name, [
      ...childsField.value.slice(0, childsField.value.length - 1),
    ]);
  };

  const handleChildAgeChange = (option: any, index: number) => {
    const newValues = childsField.value;

    newValues[index] = option.value;
    formContext.setFieldValue(childsField.name, newValues);
  };

  return (
    <div ref={containerRef} className={classes.passengersFormContainer} onClick={showPopup}>
      <span className={classes.label}>Туристы:</span>
      <div className={classes.passengersCountCt}>
        <span className={classes.passengersCount}>
          <Icon
            containerProps={{ className: classes.icon }}
            svgData={{
              src: '/svg/travel/adult-passenger.svg',
              width: 9,
              height: 21,
            }}
          />
          {adultsCountField.value}
        </span>

        {childsField.value.length ? (
          <span className={classes.passengersCount}>
            <Icon
              containerProps={{ className: classes.icon }}
              svgData={{
                src: '/svg/travel/child-passenger.svg',
                width: 9,
                height: 16,
              }}
            />
            {childsField.value.length}
          </span>
        ) : null}
      </div>

      <div className={classNames(classes.popup, { active: isMenuOpen })}>
        <div className={classes.passengersRow}>
          <span>Взрослые</span>
          <Stepper
            value={adultsCountField.value}
            onAddHandler={handleAdultCountIncreased}
            onSubtractHandler={handleAdultCountDecreased}
            valueClassName={classes.stepperValue}
            min={1}
            max={8}
          />
        </div>

        <div className={classes.passengersRow}>
          <span>Дети</span>
          <Stepper
            value={childsField.value.length}
            onAddHandler={handleChildsCountIncreased}
            onSubtractHandler={handleChildsCountDecreased}
            valueClassName={classes.stepperValue}
            max={4}
          />
        </div>

        {childsField.value.length ? (
          <FieldArray name={FORM_KEYS.childPassengers}>
            {(fieldArrayProps: any) => (
              <div className={classes.childPassengersList}>
                <span className={classes.childPassengersListDesc}>
                  Укажите возраст ребенка на момент поездки
                </span>
                {childsField.value.map((item: any, index: number) => (
                  <FormikSelect
                    defaultValue={CHILD_AGES[1]}
                    isClearable={false}
                    name={`${FORM_KEYS.childPassengers[index]}`}
                    key={index}
                    options={CHILD_AGES}
                    onChange={(option) => handleChildAgeChange(option, index)}
                  />
                ))}
              </div>
            )}
          </FieldArray>
        ) : null}
      </div>
    </div>
  );
};

export default PassengersSelect;
