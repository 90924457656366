import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { upperFirst } from 'lodash';
import React from 'react';
import { createUseStyles } from 'react-jss';

import API, { route } from '../../../../api';
import { getOptionTypeLabel } from '../../../../helpers/labels';

import { FORM_TYPES } from './data';
import FormikSelect from './select-wrapper';

const useStyles = createUseStyles<any>((theme: any) => ({
  inputCt: {
    width: '100%',
  },
  placeOfDestinationContainer: {
    '& .select__control': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,

      '@media(min-width: 1024px)': {
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 4,
      },
    },
  },
  optionLabel: {
    padding: 8,
    backgroundColor: theme.wildSand,
    color: theme.emperor,
    fontWeight: '500',
    fontSize: '0.8125rem',
    lineHeight: '1rem',
    textTransform: 'initial',
  },
}));

const PlaceOfDestinationSelect: React.FC<Props> = ({ defaultValue, formTypeId }) => {
  const classes = useStyles();

  const loadOptions = (inputValue: string) => {
    if (!inputValue) {
      return;
    }

    switch (formTypeId) {
      case FORM_TYPES.tours.id:
        return loadDestinationsOptions(inputValue);

      case FORM_TYPES.hotels.id:
        return loadHotelsOptions(inputValue);

      // case FORM_TYPES.flights.id:
      //   return loadDestinationsOptions();

      // case FORM_TYPES.transfers.id:
      //   return loadTransfersOptions(inputValue);
    }
  };

  const formatGroupLabel = (data: any) => <div className={classes.optionLabel}>{data.label}</div>;

  return (
    <div
      className={classNames(classes.inputCt, {
        [classes.placeOfDestinationContainer]: formTypeId === FORM_TYPES.hotels.id,
      })}
    >
      <FormikSelect
        defaultValue={defaultValue}
        labelText="Куда:"
        name="placeOfDestination"
        placeholder={getPlaceholder(formTypeId)}
        isClearable
        loadOptions={loadOptions}
        formatGroupLabel={formatGroupLabel}
        isAsync
      />
    </div>
  );
};

const loadDestinationsOptions = async (inputValue: string) => {
  const url = route.travel.get.placesOfDestination(upperFirst(inputValue));
  const response: AxiosResponse<any> = await API.get(url);

  return response.data.map((item: any) => {
    if (item.hotel) {
      return {
        ...item,
        countryId: item.country.id,
        hotelId: item.hotel.id,
        value: item.hotel.id,
        label: item.hotel.name,
      };
    } else if (item.region) {
      return {
        ...item,
        countryId: item.country.id,
        regionId: item.region.id,
        value: item.region.id,
        label: item.region.name,
      };
    }

    return { ...item, countryId: item.country.id, label: item.country.name };
  });
};

const loadHotelsOptions = async (inputValue: string) => {
  const url = route.travel.get.hotels(upperFirst(inputValue));
  const response: AxiosResponse<any> = await API.get(url);

  return response.data.map((item: any) => {
    const option = {
      ...item,
      countryId: item.country.id,
      regionId: item.region.id,
    };

    if (item.hotel) {
      option.label = item.hotel.name;
    } else if (item.region) {
      option.label = item.region.name;
    }

    return option;
  });
};

const loadTransfersOptions = async (inputValue: string) => {
  const url = route.travel.get.transfers(upperFirst(inputValue));
  const response: AxiosResponse<any> = await API.get(url);

  const data = response.data.reduce((newObj: any, item: any) => {
    const optionData = {
      ...item,
      endpoint: item.id,
      label: item.name,
    };

    if (!newObj[item.type]) {
      newObj[item.type] = {
        label: getOptionTypeLabel(item.type),
        options: [],
      };
    }

    newObj[item.type].options.push(optionData);

    return newObj;
  }, {});

  return Object.values(data);
};

const getPlaceholder = (formTypeId: string) => {
  switch (formTypeId) {
    // case FORM_TYPES.flights.id:
    //   return 'Например, «Вена»';

    case FORM_TYPES.hotels.id:
      // case FORM_TYPES.transfers.id:
      return 'Город, курорт или отель';

    default:
      return 'Страна, курорт или отель';
  }
};

type Props = {
  defaultValue: { label: string; value: number } | null;
  formTypeId: string;
};

export default PlaceOfDestinationSelect;
