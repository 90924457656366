import classnames from 'classnames';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { createUseStyles } from 'react-jss';
import AsyncSelect from 'react-select/async';
import Select, { components as SelectComponents } from 'react-select';

const useStyles = createUseStyles<any>((theme: any) => ({
  labelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 190,
  },
}));

const FormikSelect: React.FC<Props> = ({
  classNamePrefix,
  components = {},
  containerClassName,
  defaultValue,
  formatGroupLabel,
  isDisabled = false,
  isAsync = false,
  isClearable = true,
  isSearchable = true,
  label,
  labelClassName,
  loadOptions,
  name,
  noOptionsMessage = 'Нет опций',
  onChange,
  options = [],
  placeholder = '',
}) => {
  const classes = useStyles();

  const [field] = useField(name);
  const formContext = useFormikContext();

  const noOptionsMessageFunc = ({ inputValue }: any) => (inputValue?.length > 1 ? noOptionsMessage : null);

  const handleChange = (option: any) => {
    formContext.setFieldValue(field.name, option);

    if (typeof onChange === 'function') {
      onChange(option);
    }
  };

  const renderSelect = () => {
    if (isAsync) {
      return (
        <AsyncSelect
          classNamePrefix={classNamePrefix}
          components={components}
          cacheOptions
          defaultValue={field.value ? { value: field.value?.value, label: field.value?.label } : null}
          formatGroupLabel={formatGroupLabel}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isSearchable={isSearchable}
          loadingMessage={() => 'Загрузка'}
          loadOptions={loadOptions}
          noOptionsMessage={noOptionsMessageFunc}
          onChange={handleChange}
          placeholder={placeholder}
        />
      );
    }

    return (
      <Select
        classNamePrefix="select"
        defaultValue={defaultValue}
        components={components}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        noOptionsMessage={noOptionsMessageFunc}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
      />
    );
  };

  return (
    <label className={classnames(classes.labelWrapper, containerClassName)}>
      {label ? <span className={labelClassName}>{label}</span> : null}

      {renderSelect()}
    </label>
  );
};

type Option = {
  value: any;
  label: string;
};

export type Props = {
  classNamePrefix?: string;
  components?: Partial<typeof SelectComponents>;
  containerClassName?: any;
  defaultValue?: any;
  formatGroupLabel?: (data: any) => JSX.Element;
  isDisabled?: boolean;
  isAsync?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  label?: string;
  labelClassName?: any;
  loadOptions?: (inputValue: string) => void;
  name: string;
  noOptionsMessage?: string;
  onChange?: (option: any) => void;
  options?: Option[];
  placeholder?: string;
};

export default FormikSelect;
