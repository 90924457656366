import classNames from 'classnames';
import React from 'react';
import ReactDayPicker, { Modifier, Modifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles<any>((theme: any) => ({
  dayPicker: {
    lineHeight: 1.1,

    '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
      backgroundColor: theme.primaryColor,
    },
  },
  dayPickerRange: {
    '& .DayPicker:not(.DayPicker--interactionDisabled)': {
      '& .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover':
        {
          backgroundColor: theme.optionHover,
          borderRadius: '50%',
          color: theme.primaryColor,
        },
    },
    '& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)':
      {
        backgroundColor: theme.optionHover,
        color: theme.primaryColor,
      },
    '& .DayPicker-Day': {
      borderRadius: 0,
    },
    '& .DayPicker-Day--start': {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    '& .DayPicker-Day--end': {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },
  },
}));

const WEEKDAYS_SHORT = {
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
};
const WEEKDAYS_LONG = {
  ru: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
};
const MONTHS = {
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
};
const FIRST_DAY_OF_WEEK = {
  ru: 1,
};
const LABELS = {
  ru: { nextMonth: 'следующий месяц', previousMonth: 'предыдущий месяц' },
};
const locale = 'ru';

const DayPicker: React.FC<Props> = ({
  className,
  disabledDays,
  fromMonth,
  hasRange,
  modifiers,
  month,
  onDayClick,
  selectedDays,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.dayPicker, { [classes.dayPickerRange]: hasRange }, className)}>
      <ReactDayPicker
        disabledDays={disabledDays}
        fromMonth={fromMonth}
        modifiers={modifiers}
        month={month}
        onDayClick={onDayClick}
        selectedDays={selectedDays}
        locale={locale}
        months={MONTHS[locale]}
        weekdaysLong={WEEKDAYS_LONG[locale]}
        weekdaysShort={WEEKDAYS_SHORT[locale]}
        firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
        labels={LABELS[locale]}
      />
    </div>
  );
};

interface Props {
  className?: any;
  disabledDays?: Modifier | Modifier[];
  fromMonth?: Date;
  hasRange?: boolean;
  modifiers?: Partial<Modifiers>;
  month?: Date;
  onDayClick?: any;
  selectedDays: Modifier | Modifier[];
}

export default DayPicker;
