import classNames from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { components as SelectComponents } from 'react-select';

import Select, { Props as SelectProps } from '../../../../components/formik/select';

const useStyles = createUseStyles<any>((theme: any) => ({
  selectContainer: {
    '& .select__control': {
      height: 66,
      borderRadius: 0,
      borderColor: theme.catskillWhite,
      borderWidth: 0,
      borderBottomWidth: 1,
      color: theme.textBlack,
      lineHeight: '1.125rem',
      cursor: 'pointer',

      '@media(min-width: 1024px)': {
        borderBottom: 'none',
        borderRight: `1px solid ${theme.catskillWhite}`,
      },
    },
    '& .select__menu': {
      marginTop: 0,
      paddingLeft: 12,
      paddingRight: 12,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '& .select__group-heading': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .select__option': {
      padding: 8,
      fontSize: '0.875rem',
      lineHeight: '1rem',
    },
    '& .select__option--is-focused': {
      backgroundColor: theme.optionHover,
    },
    '& .select__option--is-selected': {
      background: 'none',
      color: theme.textBlack,

      '&:hover': {
        backgroundColor: theme.optionHover,
      },
    },
    '& .select__value-container': {
      padding: '12px 9px 14px 12px',
    },
    '& .select__single-value': {
      gridArea: '2/1/2/3',
      margin: 0,
    },
    '& .select__input-container': {
      gridArea: '2/1/2/3',
      margin: 0,
      padding: 0,
      color: theme.textBlack,
      cursor: 'text',
    },
    '& .select__placeholder': {
      gridArea: '2/1/2/3',
      margin: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  topLabel: {
    marginBottom: 6,
    color: theme.emperor,
    cursor: 'pointer',
    fontSize: '0.8125rem',
    fontWeight: '500',
    lineHeight: '0.9375rem',
    whiteSpace: 'nowrap',
  },
}));

const FormikSelect: React.FC<Props> = ({
  containerClassName,
  label,
  labelClassName,
  labelText,
  ...otherProps
}) => {
  const classes = useStyles();

  const ValueContainer = ({ children, ...rest }: any) => (
    <SelectComponents.ValueContainer {...rest}>
      <label className={classes.topLabel}>{labelText}</label>
      {children}
    </SelectComponents.ValueContainer>
  );

  return (
    <Select
      {...otherProps}
      classNamePrefix="select"
      components={{ ValueContainer, IndicatorSeparator: undefined, DropdownIndicator: undefined }}
      containerClassName={classNames(classes.selectContainer, containerClassName)}
    />
  );
};

type Props = SelectProps & { labelText: string };

export default FormikSelect;
