import { create as createJss } from 'jss';
import preset from 'jss-preset-default';
import { JssProvider, ThemeProvider } from 'react-jss';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import reportWebVitals from './reportWebVitals';
import theme from './styles/theme';
import './styles/index.css';

const jss = createJss(preset());

ReactDOM.render(
  <React.StrictMode>
    <JssProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </JssProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
