const theme = {
  brandTextGreen: '#73A200',
  brandBlue: '#0000B4',
  brandBlueFocus: '#050592',
  primaryColor: '#96D300',
  primaryFocusColor: '#80b300',
  primaryTextColor: 'white',
  secondaryColor: '#0000B4',
  optionHover: 'rgba(150, 211, 0, 0.1)',
  warningColor: '#ffae00',
  alertColor: '#cc4b37',
  darkGray: '#cacdd1',
  silver: '#c4c4c4',
  mediumGray: '#e1e1e1',
  textGray: '#6D6F72',
  textBlack: '#333333',
  tundora: '#444',
  emperor: '#555',
  doveGray: '#666666',
  boulder: '#777',
  doveGray2: '#737373',
  backgroundButton: '#efefef',
  backgroundButtonFocus: '#e2e2e2',
  black: '#0a0a0a',
  darkerGray: '#9c9fa2',
  controlHoverBackground: '#828689',
  purple: '#ba0086',
  white: '#fff',
  alabaster: '#fafafa',
  transparent: 'transparent',
  deepCerulean: '#0078AE',
  fiord: '#405265',
  fiord2: '#494E72',
  wildSand: '#F5F5F5',
  catskillWhite: '#ECF0F6',
  scarlet: '#ff2f17',
};

export type Theme = typeof theme;

export default theme;
