import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { upperFirst } from 'lodash';
import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

import DayPicker from '../../../../components/day-picker';
import Icon from '../../../../components/icon';

import { FORM_TYPES } from './data';
import { FORM_KEYS } from './travel-form';

const todayDate = new Date();
const dateFormatOptions: any = { weekday: 'short', day: 'numeric', month: 'long' };

const useStyles = createUseStyles<any>((theme: any) => ({
  endDateFormContainer: {
    position: 'relative',
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px 24px 14px 12px',
    minWidth: 180,
    height: 66,
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.catskillWhite}`,
    cursor: 'pointer',

    '&:hover': {
      borderColor: theme.silver,
    },

    '@media(min-width: 1024px)': {
      padding: '12px 12px 14px 12px',
      borderBottom: 'none',
      borderRight: `1px solid ${theme.catskillWhite}`,
    },
  },
  inputCt: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 12,
  },
  label: {
    marginBottom: 6,
    color: theme.emperor,
    fontSize: '0.8125rem',
    fontWeight: '500',
    lineHeight: '0.9375rem',
    whiteSpace: 'nowrap',
  },
  value: {
    color: theme.textBlack,
    lineHeight: '1.125rem',
    whiteSpace: 'nowrap',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  popup: {
    display: 'none',
    position: 'absolute',
    top: 66,
    left: 0,
    color: theme.textBlack,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: theme.white,
    boxShadow: '0 3px 30px rgb(0, 0, 0, 0.1);',

    '&.active': {
      display: 'block',
      zIndex: 1,
    },
  },
}));

const EndDateSelect: React.FC<Props> = ({ formTypeId }) => {
  const classes = useStyles();

  const [startDateField] = useField(FORM_KEYS.startDate);
  const [endDateField] = useField(FORM_KEYS.endDate);
  const formContext = useFormikContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [chosenDate, setChosenDate] = useState<Date>(endDateField.value);

  const handleChangeDateClick = (date: Date, modifiers: any) => {
    if (modifiers.disabled) {
      return;
    }

    setChosenDate(date);
    formContext.setFieldValue(endDateField.name, date);

    if (date.getTime() < startDateField.value.getTime()) {
      formContext.setFieldValue(startDateField.name, date);
    }
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
    document.removeEventListener('click', hidePopupByOutsideClick);
  };

  const showPopup = () => {
    document.addEventListener('click', hidePopupByOutsideClick);
    setIsPopupVisible(true);
  };

  const hidePopupByOutsideClick = (e?: any) => {
    if (containerRef === null || (containerRef.current && !containerRef.current.contains(e.target))) {
      hidePopup();
    }
  };

  return (
    <div ref={containerRef} className={classes.endDateFormContainer} onClick={showPopup}>
      <div className={classes.inputCt}>
        <span className={classes.label}>{getLabel(formTypeId)}</span>
        <span className={classes.value}>
          {upperFirst(chosenDate.toLocaleDateString('ru', dateFormatOptions))}
        </span>
      </div>

      <div className={classNames(classes.popup, { active: isPopupVisible })}>
        <DayPicker
          fromMonth={todayDate}
          hasRange={formTypeId === FORM_TYPES.tours.id}
          onDayClick={handleChangeDateClick}
          month={chosenDate}
          selectedDays={[chosenDate]}
          disabledDays={[
            {
              before: todayDate,
            },
          ]}
        />
      </div>

      <Icon
        containerProps={{ className: classes.icon }}
        svgData={{
          src: '/svg/travel/calendar.svg',
          width: 24,
          height: 24,
        }}
      />
    </div>
  );
};

const getLabel = (formTypeId: string) => {
  switch (formTypeId) {
    case FORM_TYPES.hotels.id:
      return 'Дата отъезда:';

    // case FORM_TYPES.flights.id:
    //   return 'Дата прилёта:';

    // case FORM_TYPES.transfers.id:
    //   return 'Дата вылета:';
  }
};

type Props = {
  formTypeId: string;
};

export default EndDateSelect;
